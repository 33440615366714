@import 'sanitize.css';

@custom-media --desktop (width >= 500px) and (width <= 1200px);

:root {
  --font-system-ui: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-serif: 'Roboto Slab', serif;
  --font-sans: 'Ropa Sans', sans-serif;
  --color-text: #010101;
  --color-bg: #fefefe;
  --color-link: #0e0e0e;
  --color-link-border: #0e0e0e;
  --color-link-hover: #fefefe;
  --color-link-hover-bg: #262626;
  --color-info: #fff;
  --color-main-bg: #fefefe;
  --color-yellow: #faed18;
  --color-red:#ff4800;
  --color-blue: #41d5e6;
  --color-green: #0a9797;
  --path-fill-1: #0a9797;
  --path-fill-2: #faed18;
  --path-fill-3: #41d5e6;
  --path-fill-4: #ff4800;
  --color-title: #fff;
  --font-family-title: 'Roboto Slab', serif;
  --font-size-title: 7vmax;
  --font-weight-title: 700;
  --color-menu: #171616;
  --color-menu-hover: #ffffff;
  --font-family-menu: 'Roboto Slab', serif;
  --font-size-menu: 2vmax;
  --font-weight-menu: 700;
  --button-bg: #fff;
  --button-circle: #6b6b6b;
  --button-line: #222;
}

.js body {
  opacity: 0;
  transition: opacity 0.3s;		
}

.js body.render {
  opacity: 1;
}


body {
  font-family: var(--font-sans);
  min-height: 100vh;
  color: #57585c;
  color: var(--color-text); 
  background-color: #fff;
  background-color: var(--color-bg);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 18px;
}

h1, h2, h3, h4, h5, strong {
  display: block;
  font-family: var(--font-serif);
  width: 100%;
}

strong {
  font-size: 0.84em;
  /*	margin-top: 1em;
  margin-bottom: 0.45em;*/
}


em {
  font-style: normal;
  font-weight: 700;
}

a {
  text-decoration: none;
  color: #5d93d8;
  color: var(--color-link);
  outline: none;
  border-bottom: 1px solid var(--color-link-border);
  transition: all .3s ease;
}

a:hover,
a:focus {
  color: var(--color-link-hover);
  background-color: var(--color-link-hover-bg);
  outline: none;
}

img {
}

.hidden {
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
  pointer-events: none;
}

/* Icons */
.icon {
  display: block;
  width: 1.5em;
  height: 1.5em;
  margin: 0 auto;
  fill: currentColor;
}

.icon--keyboard {
  display: none;
}

main {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: var(--color-main-bg);
}

.content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  margin: 0 auto;
}
  
  .borderb {
  border-bottom: 32px solid var(--color-link-hover-bg);
  }
.content--fixed {
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  align-content: space-between;
  width: 100%;
  max-width: none;
  min-height: 0;
  height: 100vh;
  padding: 1.5em;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto 4em;
  grid-template-areas: 'header ...'
  '... ...'
  'github demos';
}

.content--fixed a {
  pointer-events: auto;
}

.home-part {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  width: 100%;
  overflow: hidden;
  min-height: 74vh;
}


.yellow {
  background-color: var(--color-yellow);
}
.red {
  background-color: var(--color-red);
}
.blue {
  background-color: var(--color-blue);
}
.green {
  background-color: var(--color-green);
}

.home-part .cover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
  width: 42vw;
}

.home-part.single .cover  {
  width: 100%;
}

.abstract {
  font-size: 24px;
  line-height: 1.2;
}

.home-part .title {
  z-index: 1;
  font-size: 1.5em;
  margin-top: 32px;
  padding-left: 1em;
}

.home-part .title h1{
  margin-top: 32px;
}

.home-part .text {
  z-index: 1;
  line-height: 1.4;
  padding-left: 1em;
}

.home-part .text p {
  background: var(--color-main-bg);
  display: inline-block;
  max-width: 420px;
  min-width: 320px;
  margin-top: 32px;
  margin-right: 1em;
  padding: 1em 2em;
}


.item.interview {
  position: relative;
  min-height: 64vh;
  overflow:hidden;
}
.item.interview .cover {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  transition: all .2s ease;
}
.item.interview .cover:hover {
  opacity: 0.2;
}

.item.interview .text {
  z-index: 1;
  position: relative;
  transition: all .2s ease;
}

.item.interview.yellow:hover {
  background: var(--color-yellow);
}
.item.interview.red:hover {
  background: var(--color-red);
}
.item.interview.blue:hover {
  background: var(--color-blue);
}
.item.interview.green:hover {
  background: var(--color-green);
}

.item.interview .text.yellow-text {
  color: var(--color-yellow);
}
.item.interview .text.red-text {
  color: var(--color-red);
}
.item.interview .text.blue-text {
  color: var(--color-blue);
}
.item.interview .text.green-text {
  color: var(--color-green);
}

.item.interview:hover .text.yellow-text,
.item.interview:hover .text.blue-text,
.item.interview:hover .text.red-text,
.item.interview:hover .text.green-text {
  color: var(--color-text);
}
  
  .single .cover img {
    max-width: 100%;
  }
@media screen and (min-width: 55em) {
.item.interview .title h1 {
  font-size: 64px;
}
  .home-part .cover {
    width: 84vw;
  }
  .home-part {
    flex-direction: row;
  }
  .home-part .title {
    font-size: 2em;
    width: calc(50% - 1em);
    margin-top: 64px;
    padding-left: 1em;
  }
  .home-part .text {
    z-index: 1;
    width: calc(50% - 2em);
    margin-top: 64px;
    padding-left: 2em;
  }
}

@media screen and (min-width: 55em) {
  .index {
    width: 50%;
  }
}

.index .cover img {
  max-width: 100%;
}

.index a:hover {
  color: var(--color-text);
}

.item {
  min-height: 42vh;
  padding: 32px;
  transition: all .2s ease;
}

.item .title {
  
}

.item.yellow:hover {
  background-color: initial;
}
.item.red:hover {
  background-color: initial;
}
.item.blue:hover {
  background-color: initial;
}
.item.green:hover {
  background-color: initial;
}




.article {
  width: 100%;
  max-width: 660px;
  padding: 2em;
  margin: 0 auto;

  line-height: 1.4;
}

.article .formatted {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.article img {
  width: 100vw;
  max-width: 100%;
}


.text-part, .text-part-full {
  width: 100%;
  margin: 0.5em auto;
}
/* Navigation */


/* demo 3 */
.global-menu {
  width: 100vw;
  height: 90vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 100;
  padding: 64px 32px;
  line-height: 1.4;
}

.global-menu__sub {
  padding-left: 32px;
  width: calc(100% - 32px);
}

.global-menu__item {
  color: var(--color-menu);
  font-family: var(--font-family-menu);
  font-size: var(--font-size-menu);
  font-weight: var(--font-weight-menu);
  opacity: 0;
  transform: translateY(-100%);
  pointer-events: none;
  display: block;
  margin: 0.25em 0;
  transition: transform 0.1s, opacity 0.1s;
  transform: translateY(100%);
  transition-timing-function: ease-out;
  width: 100%;
}


.global-menu__item:hover {
  color: var(--color-menu-hover);
}

.global-menu__item.is-opened {
  opacity: 1;
  z-index: 9;
  transform: translateY(0) rotate(0);
  pointer-events: auto;
  transition-timing-function: ease;
}

.global-menu__item.is-opened {
  transition-duration: 0.3s;
}

.global-menu__item:nth-of-type(1) {
  transition-delay: 0s;
}

.global-menu__item.is-opened:nth-of-type(1) {
  transition-delay: 0.65s;
}

.global-menu__item:nth-of-type(2) {
  transition-delay: 0s;
}

.global-menu__item.is-opened:nth-of-type(2) {
  transition-delay: 0.7s;
}

.global-menu__item:nth-of-type(3) {
  transition-delay: 0s;
}

.global-menu__item.is-opened:nth-of-type(3) {
  transition-delay: 0.75s;
}

.global-menu__item:nth-of-type(4) {
  transition-delay: 0s;
}

.global-menu__item.is-opened:nth-of-type(4) {
  transition-delay: 0.8s;
}

@media screen and (min-width: 55em) {
.global-menu__item {
  max-width: 45%;
}
}
.shape-overlays {
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
}

.shape-overlays.is-opened {
  pointer-events: auto;
}
.shape-overlays__path:nth-of-type(1) {
  fill: var(--path-fill-1);
}
.shape-overlays__path:nth-of-type(2) {
  fill: var(--path-fill-2);
}
.shape-overlays__path:nth-of-type(3) {
  fill: var(--path-fill-3);
}
.shape-overlays__path:nth-of-type(4) {
  fill: var(--path-fill-4);
}
.shape-overlays__path:nth-of-type(4) {
  fill: var(--path-fill-4);
}

@-webkit-keyframes intervalHamburgerBorder {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  80% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
  }
}

@keyframes intervalHamburgerBorder {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  80% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
  }
}

.hamburger {
  width: 64px;
  height: 64px;
  display: block;
  position: relative;
  cursor: pointer;
  position: absolute;
  top: 2.25em;
  right: 2.25em;
  z-index: 110;
  border-radius: 50%;
  background-color: var(--button-bg);
  pointer-events: auto;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.hamburger::after {
  width: 64px;
  height: 64px;
  box-sizing: border-box;
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  -webkit-animation-duration: 1.2s;
  animation-duration: 1.2s;
  -webkit-animation-name: intervalHamburgerBorder;
  animation-name: intervalHamburgerBorder;
  -webkit-animation-iteration-count: 3;
  animation-iteration-count: 3;
}

.hamburger__line {
  width: 28px;
  height: 2px;
  overflow: hidden;
  position: absolute;
  z-index: 10;
}

.hamburger__line-in {
  width: 84px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
}

.hamburger__line-in::before,
.hamburger__line-in::after {
  width: 28px;
  height: 2px;
  content: '';
  display: block;
  position: absolute;
  top: 0;
  background-color: var(--button-line);
}

.hamburger__line-in::before {
  left: -56px;
}

.hamburger__line-in::after {
  left: 0;
}

.hamburger__line--01,
.hamburger__line--02,
.hamburger__line--03,
.hamburger__line--cross01,
.hamburger__line--cross02 {
  left: 18px;
}

.hamburger__line--01 {
  top: 24.6px;
}

.hamburger__line--02,
.hamburger__line--cross01,
.hamburger__line--cross02 {
  top: 31px;
}

.hamburger__line--03 {
  top: 37.4px;
}

.hamburger__line--cross01 {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hamburger__line--cross02 {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.hamburger__line {
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger__line-in {
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger__line-in::before,
.hamburger__line-in::after {
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.hamburger__line-in--cross01,
.hamburger__line-in--cross02 {
  -webkit-transform: translateX(-33.3%);
  transform: translateX(-33.3%);
}

.hamburger__line-in--01 {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.hamburger__line-in--02 {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}

.hamburger__line-in--02::before,
.hamburger__line-in--02::after {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

.hamburger__line-in--03 {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.hamburger__line-in--03::before,
.hamburger__line-in--03::after {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.hamburger__line-in--cross01 {
  -webkit-transition-delay: 0.0s;
  transition-delay: 0.0s;
}

.hamburger__line-in--cross02 {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

.hamburger__line-in--cross02::before,
.hamburger__line-in--cross02::after {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.hamburger.is-opened-navi .hamburger__line-in--01,
.hamburger.is-opened-navi .hamburger__line-in--02,
.hamburger.is-opened-navi .hamburger__line-in--03 {
  -webkit-transform: translateX(33.3%);
  transform: translateX(33.3%);
}

.hamburger.is-opened-navi .hamburger__line-in--cross01,
.hamburger.is-opened-navi .hamburger__line-in--cross02 {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.hamburger.is-opened-navi .hamburger__line-in--01 {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.hamburger.is-opened-navi .hamburger__line-in--02 {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

.hamburger.is-opened-navi .hamburger__line-in--03 {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.hamburger.is-opened-navi .hamburger__line-in--cross01 {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}

.hamburger.is-opened-navi .hamburger__line-in--cross02 {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.hamburger:hover .hamburger__line-in::before,
.hamburger:hover .hamburger__line-in::after {
  -webkit-transform: translateX(200%);
  transform: translateX(200%);
}

.hamburger:hover .hamburger__line-in--01::before,
.hamburger:hover .hamburger__line-in--01::after,
.hamburger:hover .hamburger__line-in--02::before,
.hamburger:hover .hamburger__line-in--02::after,
.hamburger:hover .hamburger__line-in--03::before,
.hamburger:hover .hamburger__line-in--03::after {
  -webkit-transition-duration: 1s;
  transition-duration: 1s;
}

.hamburger:hover .hamburger__line-in--cross01::before,
.hamburger:hover .hamburger__line-in--cross01::after,
.hamburger:hover .hamburger__line-in--cross02::before,
.hamburger:hover .hamburger__line-in--cross02::after {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.hamburger.is-opened-navi:hover .hamburger__line-in--cross01::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--cross01::after,
.hamburger.is-opened-navi:hover .hamburger__line-in--cross02::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--cross02::after {
  -webkit-transition-duration: 1s;
  transition-duration: 1s;
}

.hamburger.is-opened-navi:hover .hamburger__line-in--01::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--01::after,
.hamburger.is-opened-navi:hover .hamburger__line-in--02::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--02::after,
.hamburger.is-opened-navi:hover .hamburger__line-in--03::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--03::after {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}


.footer {
  padding: 32px;

  line-height: 1.4;
}

.footer {
}

.footer p:first-child {
  margin-top: 0;
}

.footer img {
  height: 42px;
}
.section {
  margin-top: 32px;
}
.section:first-child {
  padding-top: 0;
}

#mc_embed_signup { 
  font:14px var(--font-sans); 
}
#mc_embed_signup input, #mc_embed_signup .button {
  border-radius: 0;
  color: var(--color-text);
  font-weight: 700;
}

#mc_embed_signup form {
  padding: 0;
}
.single-home {
flex-direction: column;
}


  .index {
    width: 100%;
  }

.index-title {
  margin: 0 auto;
  text-align: center;
  padding: 64px 0 32px;
}

@media screen and (min-width: 55em) {
.single-home {
flex-direction: row;
}

  .footer {
    padding: 64px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 80vw;
  }
  .section {
    width: calc(50% - 32px);
    margin-right: auto;
    padding: 0 16px;
    max-width: 420px;
  }
  html,
  body {
    overflow-x: hidden;
    width: 100vw;
    height: 100%;
  }
  .hamburger {
    position: fixed;
    top: 0.5em;
    right: 0.5em;
    transform: scale(0.75);
  }
  .content {
    height: auto;
    min-height: 0;
    padding-bottom: 0;
  }
  .borderb {
    border-bottom: 128px solid var(--color-link-hover-bg);
  }
  .content--fixed {
    position: relative;
    z-index: 0;
    display: block;
    padding: 0.85em;
  }
  .text-part {
    width: 50%;
  }
}


